import React, { useState, lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import './style/styles.css';
 
// Lazy load components
const Footer = lazy(() => import('./components/Footer'));
const Testimonial = lazy(() => import('./components/Testimonial'));
const Team = lazy(() => import('./components/Team'));
const Service = lazy(() => import('./components/Service'));
const Navbar = lazy(() => import('./components/Navbar'));
const Courses = lazy(() => import('./components/Courses'));
const Categories = lazy(() => import('./components/Categories'));
const Contact = lazy(() => import('./components/Contact'));
const IconCloudDemo = lazy(() => import('./components/IconCloudDemo'));
const ScrollButtons = lazy(() => import('./components/ScrollButtons'));
const SocialMediaIcons = lazy(() => import('./components/SocialMediaIcons'));
const InterviewPreparation = lazy(() => import('./pages/Interviewpreparation'));
const TutorialBox = lazy(() => import('./pages/TutorialBox'));
const AboutInternship = lazy(() => import('./pages/AboutInternship'));
const Internship = lazy(() => import('./components/Internship'));
 
function App() {
  const [darkMode, setDarkMode] = useState(false);
 
  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };
 
  return (
    <div className={`App ${darkMode ? 'bg-dark text-light' : 'bg-light text-dark'}`}>
      <div className="navbar-container">
        <Suspense fallback={<div>Loading...</div>}>
          <Navbar darkMode={darkMode} toggleTheme={toggleTheme} />
        </Suspense>
      </div>
      <div className="app-content dotted-background">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={
              <>
                <IconCloudDemo darkMode={darkMode} />
                <Service darkMode={darkMode} />
                <Internship darkMode={darkMode} />
                <Categories darkMode={darkMode} />
                <Courses darkMode={darkMode} />
                <Team darkMode={darkMode} />
                <Testimonial darkMode={darkMode} />
                <Contact darkMode={darkMode} />
              </>
            } />
            <Route path="/interview-preparation" element={<InterviewPreparation darkMode={darkMode} />} />
            <Route path="/tutorial-box" element={<TutorialBox darkMode={darkMode} />} />
            <Route path="/about-internship" element={<AboutInternship darkMode={darkMode} />} />
          </Routes>
        </Suspense>
      </div>
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <SocialMediaIcons darkMode={darkMode} />
        </Suspense>
      </div>
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <ScrollButtons />
        </Suspense>
      </div>
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Footer darkMode={darkMode} />
        </Suspense>
      </div>
    </div>
  );
}
 
export default App;